<template>
  <section class="retargeted" v-if="retargetedProducts && retargetedProducts.length > 0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="section-heading font-weight-normal text-center d-block">Recent bekeken</span>
        </div>
      </div>
      <Carousel
        v-if="isClient"
        :autoplay="3000"
        :wrapAround="true"
        :pauseAutoplayOnHover="true"
        :itemsToShow="1"
        :breakpoints="breakpoints"
        snapAlign="start"
      >
        <Slide
          class="filtered-products text-start"
          v-for="product of retargetedProducts"
          :key="product.id"
        >
          <ProductCard :product="product" :optionHeight="getOptionHeight" />
        </Slide>

        <template #addons>
          <Navigation>
            <template #prev>
              <i class="lnr lnr-chevron-left"></i>
            </template>
            <template #next>
              <i class="lnr lnr-chevron-right"></i>
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Carousel, Slide, Navigation } from "vue3-carousel/dist/carousel.es.js";
import ProductCard from "@/base/core/components/ProductCard";

defineProps({
  page: {
    type: String,
    required: false,
    default: "home",
  },
});

const store = useStore();

const isClient = ref(false);

const breakpoints = {
  // 768px and up
  768: {
    itemsToShow: 3,
  },
};

let maxCount = 0;

const retargetedProducts = computed(() => {
  return store.getters["home/getRetargetedProducts"];
});

const getOptionHeight = computed(() => {
  retargetedProducts.value.forEach((p) => {
    if (p.configurable_options) {
      p.configurable_options.find((co) => {
        if (co.values) {
          if (co.values.length > maxCount) {
            maxCount = co.values.length;
          }
        }
      });
    }
  });
  let returnValue = Math.floor(maxCount / 4, 0) + 1;
  return returnValue;
});

onMounted(() => {
  isClient.value = true;
});
</script>

<style scoped>
:deep(.carousel__prev) {
  transform: translateY(-50%) translateX(-100%);
  font-family: "system";
  padding: 8px;
  margin-right: -8px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    left: 10px;
  }
}

:deep(.carousel__next) {
  transform: translateY(-50%) translateX(100%);
  font-family: "system";
  padding: 8px;
  margin-left: -8px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    right: 10px;
  }
}
</style>
