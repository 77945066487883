<template>
  <div class="container">
    <b-carousel id="carousel-1" v-model="slideDesktop" class="d-none d-md-block" background="fff">
      <b-carousel-slide v-for="(bSlide, index) in sliderDesktop?.slides" :key="index">
        <template #img>
          <a v-if="bSlide.link" :href="bSlide.link">
            <img :src="bSlide.optImage" :alt="bSlide.title" width="100%" height="100%" />
          </a>
          <span v-else>
            <img :src="bSlide.optImage" :alt="bSlide.title" width="100%" height="100%" />
          </span>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-carousel
      id="carousel-2"
      v-model="slideMobile"
      class="d-md-none d-block"
      background="fff"
      ride="carousel"
    >
      <b-carousel-slide v-for="(bSlide, index) in sliderMobile?.slides" :key="index">
        <template #img>
          <a v-if="bSlide.link" :href="bSlide.link">
            <img :src="bSlide.optImage" :alt="bSlide.title" width="100%" height="100%" />
          </a>
          <span v-else>
            <img :src="bSlide.optImage" :alt="bSlide.title" width="100%" height="100%" />
          </span>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const slideDesktop = ref(0);
const slideMobile = ref(0);

const sliderDesktop = computed(() => {
  const sliderName = "discount_header";
  const slider = store.getters["sliders/getSliderByIdentifier"](sliderName);
  if (slider?.slides != null) {
    slider?.slides.forEach((ele) => {
      ele.optImage = ele.media.url;
    });
  }
  return slider;
});

const sliderMobile = computed(() => {
  const sliderName = "discount_header_mobile";
  const slider = store.getters["sliders/getSliderByIdentifier"](sliderName);
  if (slider?.slides != null) {
    slider?.slides.forEach((ele) => {
      ele.optImage = ele.media.url;
    });
  }
  return slider;
});
</script>

<style scoped>
.carousel {
  text-align: start;
}

.carousel-2 {
  min-height: 25px;
}
</style>
