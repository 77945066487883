<template>
  <div ref="account">
    <BCollapse
      :id="'topIconsCollapse' + showType + icon.iconNumber"
      :class="{ collapsed: visible }"
      :aria-expanded="visible"
      v-model="visible"
      class="top-icons--drop--menu"
    >
      <div>
        <ul class="list-unstyled top-icons--drop--menu-list">
          <li>
            <BLink @click.prevent="goAccount">{{ $t("account") }}</BLink>
          </li>
          <li>
            <BLink @click.prevent="goLogout">{{ $t("logout") }}</BLink>
          </li>
        </ul>
      </div>
    </BCollapse>
  </div>
</template>

<script setup>
import { ref, useTemplateRef, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

defineProps({
  icon: {
    type: Object,
    required: true,
  },
  showType: {
    type: String,
    required: false,
  },
});

const store = useStore();
const router = useRouter();
const visible = ref(false);
const accountRef = useTemplateRef("account");

// Check if the code is running on the server
const isServer = typeof window === "undefined";

// Map Vuex actions
// const setProductStatus = () => store.dispatch("setProductStatus");
// const removeItem = (id) => store.dispatch("user/removeProductWishlist", { id, store });
const goLogout = () => {
  document.body.classList.remove("noScroll2");
  store.dispatch("user/logout", { store });
  router.push("/");
};
const goAccount = () => {
  visible.value = false;
  router.push("/account");
};

function closeDropdownMenu(e) {
  if (
    accountRef.value &&
    accountRef.value.parentElement &&
    !accountRef.value.parentElement.contains(e.target)
  ) {
    visible.value = false;
    document.removeEventListener("click", closeDropdownMenu);
  }
}

watch(visible, () => {
  if (!isServer) {
    if (visible.value === true) {
      document.addEventListener("click", closeDropdownMenu);
      document.body.classList.add("noScroll2");
    } else {
      document.body.classList.remove("noScroll2");
    }
  }
});

// function closeOnClick() {
//   visible.value = false;
// }
</script>
<style scoped lang="scss">
.top-icons--drop--menu-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0;
}
</style>
