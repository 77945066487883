<template>
  <div class="nav-form">
    <div class="d-md-none">
      <a class="lnr lnr-magnifier" href="#" @click.prevent="toggleSearch"></a>
    </div>
    <div class="search-group" :class="{ show: isSearchGroupShown }">
      <BButton @click="goClose()" class="close-btn" variant="light" squared
        >X</BButton
      >
      <BFormInput
        ref="searchInput"
        v-model="search"
        type="text"
        required
        @blur="hideSearchResultsTimer()"
        :placeholder="$t('search_placeholder')"
        @keyup.enter="goSearch()"
        class="search-input"
      ></BFormInput>
      <BButton
        @click="goSearch()"
        class="search-btn"
        variant="light"
        squared
        aria-label="Search"
      >
        <i class="lnr lnr-magnifier"></i>
      </BButton>

      <div class="search-results" :class="{ show: isSearchResultsShown }">
        <span class="search--title">{{ $t("suggestions") }}:</span>
        <ul class="search-suggestions">
          <li
            v-for="suggestion in searchSuggestions"
            :key="suggestion.word"
            class="d-block"
          >
            <a @click.prevent="goRoute('/search', suggestion.word)">{{
              suggestion.word
            }}</a>
          </li>
        </ul>
        <span class="search--title">{{ $t("recommended_products") }}:</span>
        <ul class="recommended-products">
          <li
            v-for="recommendedProduct in recommendedProducts"
            :key="recommendedProduct.sku"
            class="d-inline-block"
          >
            <a
              @click.prevent="goRoute('/' + recommendedProduct.url_key)"
              class="d-flex align-items-center"
            >
              <div class="product-img">
                <b-img
                  :src="recommendedProduct.image.small"
                  :alt="recommendedProduct.name"
                  width="41"
                  height="51"
                  fluid
                ></b-img>
              </div>
              <span class="product-name">{{ recommendedProduct.name }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { ref, watch, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n();
const router = useRouter();
const search = ref("");
const searchTimer = ref(null);
const closeSignal = ref(null);
const output = ref(null);
const searchSuggestions = ref([]);
const recommendedProducts = ref([]);
const isSearchGroupShown = ref(false);
const isSearchResultsShown = ref(false);
const searchInput = ref(null);

const resize = () => {
  document.body.classList.add("resize-active");
  if (window.innerWidth > 575) {
    isSearchGroupShown.value = false;
  }
  clearTimeout(searchTimer.value);
  resizeTimer();
};

const resizeTimer = () => {
  setTimeout(() => {
    document.body.classList.remove("resize-active");
  }, 400);
};

const toggleSearch = async () => {
  isSearchGroupShown.value = !isSearchGroupShown.value;

  await nextTick();

  if (isSearchGroupShown.value && searchInput.value) {
    searchInput.value?.focus();
  }
};

const close = () => {
  isSearchGroupShown.value = false;
};

const showSearchResults = () => {
  isSearchResultsShown.value = true;
};

const hideSearchResults = () => {
  isSearchResultsShown.value = false;
};

const getSearchPreview = async () => {
  const controller = new AbortController();
  const { signal } = controller;
  closeSignal.value = controller;

  output.value = await getProductsBySearchPreview(search.value, signal);
  closeSignal.value = null;

  recommendedProducts.value = output.value.items;
  searchSuggestions.value = output.value.search_suggestions;
  showSearchResults();
};

const goRoute = (route, query = null) => {
  hideSearchResults();
  search.value = "";
  if (query != null) {
    router.push({ path: route, query: { q: query } });
  } else {
    router.push({ path: route });
  }
};

const goSearch = () => {
  hideSearchResults();
  if (
    search.value.trim().length > 0 &&
    search.value.trim().length < 35 &&
    !search.value.includes("/")
  ) {
    clearTimeout(searchTimer.value);
    if (closeSignal.value !== null) closeSignal.value.abort();
    router.push({
      path: "/search",
      query: { q: search.value.trim() },
    });
  } else if (search.value.trim().length > 35 && !search.value.includes("/")) {
    const msg = {
      type: "danger",
      title: t("search_error"),
      text: t("searchname_too_long"),
    };
    store.dispatch("messages/sendMessage", { message: msg });
  } else if (search.value.includes("/")) {
    const msg = {
      type: "danger",
      title: t("search_error"),
      text: t("search_validation"),
    };
    store.dispatch("messages/sendMessage", { message: msg });
  }
};

const goClose = () => {
  close();
};

const hideSearchResultsTimer = () => {
  return setTimeout(() => {
    search.value = "";
    hideSearchResults();
  }, 300);
};

watch(search, () => {
  if (search.value.length > 2) {
    if (searchTimer.value !== null) {
      clearTimeout(searchTimer.value);
    }
    searchTimer.value = setTimeout(() => {
      if (closeSignal.value !== null) {
        closeSignal.value.abort();
      }
      getSearchPreview();
    }, 2000);
  } else {
    clearTimeout(searchTimer.value);
    if (closeSignal.value !== null) {
      closeSignal.value.abort();
    }
    hideSearchResults();
  }
});

onMounted(() => {
  window.addEventListener("resize", resize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", resize);
});
</script>

<style scoped lang="scss">
.product-img {
  .img-fluid,
  .img-thumbnail {
    max-width: 100%;
    height: 80%;
  }
}
</style>
