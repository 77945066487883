<template>
  <section class="subscribeSeparator text-light">
    <div class="container">
      <div
        class="d-flex flex-column flex-lg-row align-items-center justify-content-center bg-dark px-20 px-md-10 pl-xl-20"
      >
        <div class="d-flex align-items-center mb-15 mb-lg-0">
          <div class="icon ratio">
            <img src="@/base/assets/email-icon.png" alt="email" />
          </div>
          <div class="text-center">
            <span class="d-block">{{ $t("newsletter_advantage") }}</span>
            <span class="d-block">{{ $t("receive_newsletter") }}</span>
          </div>
        </div>
        <form class="subscribe-form d-flex align-items-center">
          <input
            v-model="email"
            type="email"
            id="subscribe--email"
            :placeholder="$t('newsletter_placeholder')"
            class="text-dark"
            aria-label="Email"
          />
          <button type="button" @click="subscribe(email)" class="btn subscribe-button">
            {{ $t("subscribe_newsletter") }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useNewsletter } from "@storefront/core/composable/email";
const { subscribe } = useNewsletter();

const email = ref("");
</script>

<style scoped lang="scss">
.subscribeSeparator .container .icon {
  aspect-ratio: 2/1;
}
</style>
