<template>
  <BCollapse :id="collapseId" class="sub-menu" v-model="visible">
    <div v-if="!mobileCheck" ref="submenu">
      <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)">
        <template v-for="(menuItem, i) in menu" :key="menuItem.id">
          <li v-if="menuItem.name && i < 24">
            <BLink v-on:click="closeInternal" :to="menuItem.linkTo">
              {{ CapitalizeFirst(menuItem.name) }}
            </BLink>

            <div v-if="readMore['menu' + menuArray.indexOf(menu)] && i >= 24">
              <BLink v-on:click="closeInternal" :to="menuItem.linkTo">
                {{ CapitalizeFirst(menuItem.name) }}
              </BLink>
            </div>
          </li>
        </template>
        <div v-if="menu.length > 24">
          <div v-if="!readMore['menu' + menuArray.indexOf(menu)]">
            <BLink
              style="display: block; cursor: pointer; font-weight: 500"
              @click.stop="showMore('menu' + menuArray.indexOf(menu))"
              >{{ $t("show_more") }}</BLink
            >
          </div>

          <div v-if="readMore['menu' + menuArray.indexOf(menu)]">
            <BLink
              style="display: block; cursor: pointer; font-weight: 500"
              @click.stop="showLess('menu' + menuArray.indexOf(menu))"
            >
              {{ $t("show_less") }}
            </BLink>
          </div>
        </div>
      </ul>
    </div>
    <div v-else>
      <ul v-for="menu in menuArray" :key="'menu' + menuArray.indexOf(menu)" id="`menu${menu.id}`">
        <li v-for="menuItem in menu" :key="menuItem.id">
          <div class="mobile-sub-wrap">
            <BLink @click="closeInternal" :to="menuItem.linkTo">{{ menuItem.name }}</BLink>
            <BLink @click.stop="$root.$emit('bv::toggle::collapse', `subMenu${menuItem.id}`)"
              ><i v-if="menuItem.length" class="fa fa-chevron-right"></i
            ></BLink>
          </div>
          <BCollapse v-if="menuItem.length" :id="`subMenu${menuItem.id}`">
            <ul class="submenu-child">
              <li v-for="subItem in menuItem" :key="subItem.id">
                <BLink v-if="subItem.include_in_menu" :to="`/${subItem.url_path}`">{{
                  subItem.name
                }}</BLink>
              </li>
            </ul>
          </BCollapse>
        </li>
      </ul>
    </div>
  </BCollapse>
</template>

<script setup>
import { ref, computed, nextTick, watch } from "vue";
import { isMobile, isTablet } from "mobile-device-detect";
import { useStore } from "vuex";

const props = defineProps(["menuArray", "collapseId", "modelValue", "id"]);
const emit = defineEmits(["setHeight", "resetHeight"]);

const readMore = ref({});

// Computed properties
const mobileCheck = computed(() => isMobile || isTablet);
// const mob = computed(() => isMobile);
// const mobtab = computed(() => isTablet);

const store = useStore();

const visible = computed({
  get() {
    return store.getters["menu/getMenuItemState"](props.id).state;
  },
  set(val) {
    store.commit("menu/updateMenuState", { id: props.id, state: val });
  },
});

const closeInternal = () => {
  visible.value = false;
  store.commit("menu/setNavBar", false);
};

const showMore = (id) => {
  readMore.value[id] = true;
  setMenuHeight();
};

const showLess = (id) => {
  readMore.value[id] = false;
  setMenuHeight();
};
const submenu = ref(null);
const setMenuHeight = () => {
  nextTick(() => {
    const subMenu = submenu.value;
    if (subMenu) {
      const height = subMenu.clientHeight;
      emit("setHeight", height);
    }
  });
};

const resetMenuHeight = () => {
  emit("resetHeight");
};

const CapitalizeFirst = (input) => {
  const lower = input.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};
// Watchers
watch(visible, (value) => {
  if (value) {
    setMenuHeight();
  } else {
    resetMenuHeight();
  }
});
</script>
