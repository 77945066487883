<template>
  <ul class="top-icons">
    <li v-for="icon of icons" :key="icon.iconNumber">
      <BLink
        v-if="icon.isEnabled"
        :to="icon.link"
        @click.prevent="onClick"
        v-b-toggle="`topIconsCollapse${showType}${icon.iconNumber}`"
      >
        <i v-if="icon.isLinearIcon" class="lnr" :class="icon.iconName"></i>
        <div class="img" v-else>
          <img
            :src="icon.iconName"
            :srcset="`${icon.iconName} 1x, ${icon.iconName} 2x`"
            width="25"
            height="25"
            :alt="`${icon.text} Image`"
          />
        </div>
        <span>{{ icon.text }}</span>
        <BBadge v-if="icon.hasBadge" :variant="icon.badgeColor" :key="icon.badgeValue">{{
          icon.badgeValue
        }}</BBadge>
      </BLink>
      <component :is="renderPopovers(icon)" :icon="icon" :showType="showType"></component>
      <!-- <HeaderIconsDropdownCart
        v-if="icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true"
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdown
        v-if="icon.hasDropdown && icon.menuId == 'wishList' && icon.isEnabled == true"
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdownMenu
        v-if="icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true"
        :icon="icon"
        :showType="showType"
      /> -->
    </li>
  </ul>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import HeaderIconsDropdown from "@/base/core/components/HeaderIconsDropdown.vue";
import HeaderIconsDropdownCart from "@/base/core/components/HeaderIconsDropdownCart.vue";
import HeaderIconsDropdownMenu from "@/base/core/components/HeaderIconsDropdownMenu.vue";
import infoIcon from "@/base/assets/icon-info.png";
import { useCartInfo } from "@storefront/core/composable/cart";

const { cartCount, wishlistCount, cartItems, wishlistItems, cartSubTotalPrice, isLoggedIn } =
  useCartInfo();
const { t } = useI18n();

defineProps({
  showType: String,
});

// const wishlistCount = computed(() => store.getters["user/getWishlistQuantity"]);
// const isLoggedIn = computed(() => store.getters["user/getIsLoggedIn"]);
// const cartCount = computed(() => {
//   return store.getters["cart/getCartIsLoaded"] ? store.getters["cart/getItemsTotalQuantity"] : 0;
// });
// const cartItems = computed(() => store.getters["cart/getCartItems"]);
// const wishlistItems = computed(() => store.getters["user/getWishlist"]);
// const cartSubTotalPrice = computed(() => {
//   if (store.getters["cart/getCartIsLoaded"]) {
//     const prices = store.getters["cart/getCartPrices"];
//     return prices?.subtotal_including_tax?.value || 0;
//   }
//   return 0;
// });

const icons = computed(() => [
  {
    iconNumber: 1,
    isLinearIcon: true,
    hasBadge: false,
    iconName: "lnr-map-marker",
    text: "Onze winkel",
    hasDropdown: false,
    link: "/winkels",
    isEnabled: true,
  },
  {
    iconNumber: 2,
    isLinearIcon: false,
    hasBadge: false,
    iconName: infoIcon,
    text: "Klantenservice",
    hasDropdown: false,
    link: "/contact",
    isEnabled: true,
  },
  {
    iconNumber: 3,
    isLinearIcon: true,
    hasBadge: true,
    badgeValue: cartCount.value,
    badgeColor: "success",
    iconName: "lnr-cart",
    text: "Winkelmand",
    isEnabled: true,
    hasDropdown: true,
    dropHeading: t("items_in_cart", { numberOf: cartCount.value }),
    dropItems: cartItems.value,
    menuId: "cart",
    cartFooter: {
      totalPrice: cartSubTotalPrice.value,
      btnText: t("goto_checkout"),
      btnVariant: "success",
    },
  },
  {
    iconNumber: 4,
    isLinearIcon: true,
    hasBadge: true,
    badgeValue: wishlistCount.value,
    badgeColor: "success",
    iconName: "lnr-heart",
    text: "Wensenlijst",
    hasDropdown: true,
    menuId: "wishList",
    isEnabled: isLoggedIn.value,
    dropHeading: t("items_in_wishlist", {
      numberOf: wishlistCount.value,
    }),
    dropItems: wishlistItems.value,
  },
  {
    iconNumber: 5,
    isLinearIcon: true,
    hasBadge: false,
    iconName: "lnr-user",
    text: t("login"),
    hasDropdown: false,
    link: "/login",
    isEnabled: !isLoggedIn.value,
  },
  {
    iconNumber: 6,
    isLinearIcon: true,
    hasBadge: false,
    iconName: "lnr-user",
    text: t("account"),
    menuId: "account",
    hasDropdown: true,
    isEnabled: isLoggedIn.value,
  },
]);

function onClick() {}

function renderPopovers(icon) {
  let component = null;
  if (icon.hasDropdown && icon.isEnabled == true) {
    switch (icon.menuId) {
      case "cart":
        component = HeaderIconsDropdownCart;
        break;
      case "wishList":
        component = HeaderIconsDropdown;
        break;
      case "account":
        component = HeaderIconsDropdownMenu;
        break;
    }
  }
  return component;
}
</script>

<style scoped>
.non-link-icons {
  cursor: pointer;
}
</style>
