<template>
  <BCarousel
    id="carousel-hero-banner"
    v-model="slide"
    ride="carousel"
    :interval="5000"
    indicators
    style="text-shadow: 1px 1px 2px #333"
    class="hero-banner-carousel container"
    background="fff"
  >
    <!-- @sliding-start="onSlideStart" -->
    <!-- @sliding-end="onSlideEnd" -->
    <BCarouselSlide
      v-for="(bSlide, index) in slider?.slides"
      :key="index"
      :img-src="bSlide.optImage"
      :id="'carousel-hero-banner' + index"
    >
      <template v-slot:img>
        <BLink :to="bSlide.link" class="ml-md-0 w-100">
          <img
            class="d-block img-fluid w-100"
            :src="bSlide.optImage"
            :alt="bSlide.title"
            width="767"
            height="286"
          />
          <div class="carousel-caption">
            <div class="container">
              <div
                class="carousel-caption"
                v-html="bSlide.description"
                v-if="bSlide.description"
              ></div>
              <BLink
                :to="bSlide.link"
                v-if="bSlide.buttonText"
                class="btn btn-trans-white ml-md-0"
                >{{ bSlide.buttonText }}</BLink
              >
            </div>
          </div>
        </BLink>
      </template>
    </BCarouselSlide>
  </BCarousel>
</template>

<script setup>
import { ref, computed } from "vue";
import { isMobile, isTablet, isBrowser } from "mobile-device-detect";
import { useStore } from "vuex";

const slide = ref(0);
// const sliding = ref(false);
const store = useStore();

// Computed for checking the device type
const mobileCheck = computed(() => {
  if (isBrowser) {
    return "browser";
  } else if (isMobile && isTablet) {
    return "tablet";
  } else {
    return "mobile";
  }
});

// Computed for getting the slider data and modifying it based on the device type
const slider = computed(() => {
  let slider = store.getters["sliders/getSliderByIdentifier"]("home");

  if (slider?.slides != null) {
    slider.slides.forEach((element) => {
      if (mobileCheck.value === "browser") {
        element.optImage = element.media.url;
      } else if (mobileCheck.value === "tablet") {
        element.optImage = element.media.larger;
      } else {
        element.optImage = element.media.medium;
      }
    });
  }
  return slider;
});

// Methods
// const onSlideStart = () => {
//   sliding.value = true;
// };

// const onSlideEnd = () => {
//   sliding.value = false;
// };
</script>
<style scoped lang="scss">
.carousel-inner .active {
  display: none;
}
.carousel-inner .carousel-item {
  display: flex !important;

  @media only screen and (max-width: 1139px) {
    min-height: 139.83px;
  }
}
.carousel {
  text-align: start;
}

#carousel-hero-banner {
  min-height: 139.8px;
}
</style>
