<template>
  <header id="header" class="pt-6 pt-md-10 pb-md- pb-lg-1">
    <BContainer class="container">
      <div class="d-flex align-items-center justify-content-center position-relative">
        <div class="logo me-lg-20">
          <router-link to="/">
            <img
              src="@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png"
              srcset="
                @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png 253w,
                @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png 175w,
                @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png  75w
              "
              width="320"
              height="57"
              alt="Logo"
            />
          </router-link>
        </div>
        <div
          class="d-flex align-items-center justify-content-end search-and-icons"
          v-if="windowSize.width >= 992"
        >
          <HeaderSearch />
          <HeaderTopIcons showType="O" />
        </div>
      </div>
      <HeaderSeparator />
      <div class="d-flex pt-1 pt-lg-0 position-relative">
        <Navbar />
        <div
          class="d-flex align-items-center justify-content-end search-and-icons pt-6"
          v-if="windowSize.width < 992"
        >
          <HeaderSearch />
          <HeaderTopIcons showType="M" />
        </div>
      </div>
    </BContainer>
    <DiscountCode />
  </header>
</template>

<script setup>
import HeaderSearch from "@/base/core/components/HeaderSearch";
import HeaderTopIcons from "@/base/core/components/HeaderTopIcons";
import HeaderSeparator from "@/base/core/components/HeaderSeparator";
import Navbar from "@/base/core/components/Navbar";
import DiscountCode from "@/base/core/components/DiscountCode";
import { useWindowSize } from "@storefront/core/composable/useWindowSize";

const windowSize = useWindowSize();
</script>
