<template>
  <footer id="footer">
    <back-to-top class="back-to-top"></back-to-top>
    <FooterDeliverySeparator />
    <div class="container">
      <div
        v-if="accdata.length > 0"
        class="d-flex flex-column flex-md-row flex-wrap justify-content-start flex-container flex-column flex-md-row"
      >
        <template v-if="isClient">
          <el-collapse
            class="d-flex flex-column flex-md-row flex-wrap justify-content-start flex-column flex-md-row"
            accordion
          >
            <FooterAccordion
              v-for="(accdatum, index) in accdata"
              :key="index"
              :accordionData="accdatum"
              :index="index"
            />
          </el-collapse>
        </template>

        <div class="contacts-block" v-if="typeof contactData == 'object'">
          <span class="text-uppercase block-heading">
            {{ contactData.title }}
          </span>
          <cmsBlockSimple
            v-if="typeof contactData == 'object'"
            :identifier="contactData.identifier"
          />
        </div>
        <div class="social-block">
          <div class="social-rating-img d-block">
            <div v-if="allCookies == true && isClient">
              <iframe
                frameborder="0"
                allowtransparency="true"
                src="https://www.kiyoh.com/retrieve-widget.html?color=white&allowTransparency=false&button=true&lang=nl&tenantId=98&locationId=1047621"
                width="120"
                height="160"
                title="Customer reviews and ratings from Kiyoh"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row flex-container delivery-and-payment">
        <div class="payment">
          <span class="text-uppercase block-heading d-block">{{ $t("secure_payments") }}</span>
          <div v-if="typeof paymentPartners == 'object'">
            <ul class="payment-partners">
              <li v-for="(image, index) in paymentPartners.slides" :key="index">
                <b-link
                  class="img"
                  :href="image.link ?? '#'"
                  :disabled="!image.link"
                  target="_blank"
                >
                  <img
                    :src="image.media.url"
                    :alt="image.title"
                    class="delivery-icons"
                    width="100%"
                    height="100%"
                  />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="delivery">
          <span class="text-uppercase block-heading d-block">
            {{ $t("delivered_by") }}
          </span>
          <div v-if="typeof deliveryPartners == 'object'">
            <ul class="delivery-partners">
              <li v-for="(image, index) in deliveryPartners.slides" :key="index">
                <b-link
                  class="img"
                  :href="image.link ?? '#'"
                  :disabled="!image.link"
                  target="_blank"
                >
                  <img class="delivery-icons" :src="image.media.url" :alt="image.title" />
                </b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <FooterCopyRight />
  </footer>
</template>

<script setup>
import FooterDeliverySeparator from "@/base/core/components/FooterDeliverySeparator.vue";
import FooterAccordion from "@/base/core/components/FooterAccordion.vue";
import cmsBlockSimple from "@/base/core/components/core/BlockSimple.vue";
import BackToTop from "@/base/core/components/core/BackToTop.vue";
import FooterCopyRight from "@/base/core/components/FooterCopyRight.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useGtm } from "@gtm-support/vue-gtm";

const store = useStore();
const gtm = useGtm();

const isClient = ref();

const accdata = computed(() => {
  return [
    store.getters[`cmsBlock/getCmsBlockByIdentifier`]("footer_menu_information"),
    store.getters[`cmsBlock/getCmsBlockByIdentifier`]("footer_menu_service"),
    store.getters[`cmsBlock/getCmsBlockByIdentifier`]("footer_menu_quick_links"),
  ];
});

const allCookies = computed(() => {
  return gtm.enabled();
});

const deliveryPartners = computed(() => {
  return store.getters["sliders/getSliderByIdentifier"]("delivery_partners");
});

const paymentPartners = computed(() => {
  return store.getters["sliders/getSliderByIdentifier"]("payment_providers");
});

const contactData = computed(() => {
  return store.getters[`cmsBlock/getCmsBlockByIdentifier`]("footer_contact");
});

onMounted(() => {
  isClient.value = true;
});
</script>
