<template>
  <section class="our-brands">
    <div class="container">
      <span class="d-block section-heading text-center font-weight-normal">{{
        $t("our_brands")
      }}</span>
      <div>
        <Carousel
          v-if="isClient"
          @slide-start="loadMoreBrands"
          :autoplay="3000"
          :wrapAround="true"
          :breakpoints="breakpoints"
          :pauseAutoplayOnHover="true"
          :itemsToScroll="1"
          snapAlign="start"
          :ref="(el) => (noOfSlidePerPage = el && el.data.config.itemsToShow)"
        >
          <Slide
            class="col-6 col-md-4 col-lg-2 d-flex justify-content-center align-items-center img-holder position-relative"
            v-for="(brand, index) in sliceOfBrandSlider"
            :key="index"
            :aria-hidden="brand.category == null ? 'true' : 'false'"
            height="133px"
          >
            <router-link
              v-if="brand.category != null"
              :to="`/${brand.category.url_path}`"
              v-bind:tabindex="brand.category == null ? '-1' : '0'"
            >
              <img v-lazy="brand.logo" :alt="brand.title" loading="lazy" />
            </router-link>

            <img
              v-if="brand.category == null"
              style="filter: grayscale(100%)"
              v-lazy="brand.logo"
              :alt="brand.title"
              loading="lazy"
            />
          </Slide>
          <template #addons>
            <Navigation>
              <template #prev>
                <i class="lnr lnr-chevron-left"></i>
              </template>
              <template #next>
                <i class="lnr lnr-chevron-right"></i>
              </template>
            </Navigation>
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Carousel, Slide, Navigation } from "vue3-carousel/dist/carousel.es.js";

const noOfSlidePerPage = ref(6);

const breakpoints = {
  0: {
    itemsToShow: 2,
  },
  768: {
    itemsToShow: 3,
  },
  992: {
    itemsToShow: 6,
  },
};

const store = useStore();

const isClient = ref(false);
const slider = computed(() => store.getters["product/getBrandSlider"]);

const sliceOfBrandSlider = ref(slider.value.slice(0, 10));

// Function to load more brands
function loadMoreBrands(data) {
  if (
    data.slidingToIndex >= data.slidesCount - noOfSlidePerPage.value &&
    data.slidingToIndex - data.slidesCount < noOfSlidePerPage.value &&
    data.slidesCount < slider.value.length
  ) {
    let currentCount = sliceOfBrandSlider.value.length;
    const moreBrands = slider.value.slice(currentCount, currentCount + 10);
    sliceOfBrandSlider.value = [...sliceOfBrandSlider.value, ...moreBrands];
  }
}

onMounted(() => {
  isClient.value = true;
});
</script>
<style scoped>
:deep(.carousel__track) {
  margin: unset !important;
}
:deep(.carousel__prev),
:deep(.carousel__next) {
  background-color: white;
  margin: 0;
  height: auto;
}
</style>
