<template>
  <div ref="cart">
    <BCollapse
      :id="'topIconsCollapse' + showType + icon.iconNumber"
      :class="{ collapsed: visible }"
      :aria-expanded="visible"
      class="top-icons--drop cart"
      v-model="visible"
      @shown="recalculateHeight"
      :is-nav="true"
      :style="[isLoggedIn ? 'right : -95px' : 'right : -75px']"
    >
      <component :is="`style`" v-html="dropDownStyle"></component>
      <span v-if="icon.dropHeading" class="drop-heading" v-html="formattedDropHeading"></span>
      <ul class="drop-items">
        <li v-for="dropItem of icon.dropItems" :key="dropItem.dropItemId">
          <div class="d-flex align-items-start">
            <div class="drop-item--img">
              <img
                :src="dropItem.product.image.x_small"
                :alt="dropItem.product.name"
                style="width: 100%; height: auto"
              />
            </div>
            <div class="drop-item--info">
              <span class="product-name d-block">{{ dropItem.product.name }}</span>
              <div v-if="typeof dropItem.configurable_options != 'undefined'">
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title">{{ option.option_label }}:&nbsp;</span>
                  <span class="product-options-value">{{ option.value_label }}</span>
                </span>
              </div>
              <div v-if="typeof dropItem.bundle_options != 'undefined'">
                <div>
                  <span
                    v-for="(option, index) of dropItem.bundle_options"
                    :key="index"
                    class="product-options d-block"
                  >
                    <span>{{ option.label }}: </span>

                    <span v-if="typeof option.values[0].configurable_options != 'undefined'">
                      <span v-for="(opt, idx) of option.values[0].configurable_options" :key="idx">
                        <span class="product-options-title">{{ opt.option_label }}:&nbsp;</span>
                        <span class="product-options-value">{{ opt.value_label }}</span>
                      </span>
                    </span>
                  </span>
                </div>
                <span
                  v-for="(option, index) of dropItem.configurable_options"
                  :key="index"
                  class="product-options d-block"
                >
                  <span class="product-options-title">{{ option.option_label }}:&nbsp;</span>
                  <span class="product-options-value">{{ option.value_label }}</span>
                </span>
              </div>

              <div class="quantity-price-box">
                <div class="price-box price">
                  &euro;&nbsp;
                  {{ dropItem.prices.row_total_including_tax.value.toFixed(2).replace(".", ",") }}
                </div>
                <div class="quantity-box">
                  <div class="quantity-input d-flex">
                    <a
                      href="#"
                      class="step-down-btn"
                      @click.prevent="quantityDown(dropItem.id, dropItem.quantity)"
                    ></a>
                    <input
                      type="number"
                      min="1"
                      :value="dropItem.quantity"
                      name="quantity"
                      ref="inputQuantity"
                      disabled
                    />
                    <a
                      href="#"
                      class="step-up-btn"
                      @click.prevent="quantityUp(dropItem.id, dropItem.quantity)"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="#" class="lnr lnr-cross" @click.prevent="removeItem(dropItem.id)"></a>
        </li>
      </ul>
      <div class="d-flex drop-bottom align-items-center">
        <span class="total">
          {{ $t("total") }}: € {{ icon.cartFooter.totalPrice.toFixed(2).replace(".", ",") }}</span
        >
      </div>
      <div
        class="d-flex drop-bottom justify-content-between align-items-center"
        v-if="icon.cartFooter"
      >
        <router-link
          :class="{ 'goto-cart-disabled': btnDisable }"
          to="/checkout/cart"
          @click.stop="closeOnClick"
        >
          {{ $t("goto_cart") }}
        </router-link>
        <BButton
          variant="success"
          @click.stop="onClickCheckout"
          v-bind:disabled="btnDisable || route.path === '/checkout'"
        >
          <i v-if="!isServer" class="fa fa-check-circle"></i>
          <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
        </BButton>
      </div>
      <div class="language-block" v-if="icon.language">
        <ul class="d-flex list-unstyled">
          <li v-for="lang of icon.language" :key="lang.alt">
            <BLink :href="lang.href">
              <img :src="lang.img" :alt="lang.alt" />
            </BLink>
          </li>
        </ul>
      </div>
    </BCollapse>
  </div>
</template>

<script setup>
import { ref, computed, useTemplateRef, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCartInfo } from "@storefront/core/composable/cart";
import { useStore } from "vuex";

const props = defineProps({
  icon: Object,
  showType: String,
});

const { quantityDown, quantityUp, removeItem, cartCount } = useCartInfo();
const router = useRouter();
const route = useRoute();
const store = useStore();

// Check if the code is running on the server
const isServer = typeof window === "undefined";

const visible = ref(false);
const dropDownStyle = ref("max-height: calc(100vh - 0px) !important");
const cartRef = useTemplateRef("cart");

const btnDisable = computed(() => {
  return cartCount.value <= 0;
});

const isLoggedIn = computed(() => store.getters["user/getIsLoggedIn"]);

function closeDropdownMenu(e) {
  const cartDropdown = cartRef.value && cartRef.value.parentElement;
  if (cartDropdown && !cartDropdown.contains(e.target)) {
    visible.value = false;
    document.removeEventListener("click", closeDropdownMenu);
  }
}

watch(visible, () => {
  if (!isServer) {
    if (visible.value === true) {
      document.addEventListener("click", closeDropdownMenu);
      document.body.classList.add("noScroll");
    } else {
      document.body.classList.remove("noScroll");
    }
  }
});

function closeOnClick() {
  visible.value = false;
}

function recalculateHeight() {
  if (!isServer) {
    const cartDropdown = cartRef.value;
    var height = cartDropdown ? cartDropdown.getBoundingClientRect().top : 0;
    dropDownStyle.value =
      "#topIconsCollapseM3 { max-height: calc(100vh - " + height + "px) !important}";
  }
}

// Compute the item count
const itemCount = computed(() => cartCount.value);

// Decode HTML entities function
const decodeHtmlEntities = (str) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = str;
  return textarea.value;
};

// Handling both client and server side
const decodeHtmlEntitiesSafe = (str) => {
  if (typeof window !== "undefined") {
    return decodeHtmlEntities(str);
  } else {
    // Server-side rendering or non-DOM environment
    return str.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
  }
};

const formattedDropHeading = computed(() => {
  if (props.icon && props.icon.dropHeading) {
    // Decode HTML entities
    const decodedString = decodeHtmlEntitiesSafe(props.icon.dropHeading);
    // Replace placeholder with actual count
    return decodedString.replace(/{numberOf}/g, itemCount.value);
  }
  return "";
});

const onClickCheckout = () => {
  visible.value = false;
  router.push({ name: "checkout" });
};
</script>

<style scoped lang="scss">
.top-icons--drop {
  width: calc(100vw - 30px);
  min-width: 290px;
  max-width: 446px;

  &.cart .drop-bottom .total {
    color: #1d71b8;
    padding-left: 0;
    margin-left: auto;
  }

  .drop-heading {
    display: block;
    font-size: 16px;
    color: #000;
    margin-bottom: 14px;
    position: relative;

    :deep(.items-count) {
      color: #5db71b;
    }

    &::after {
      content: "";
      position: absolute;
      top: calc(100% + 14px);
      left: -14px;
      right: -14px;
      background: #f3f3f3;
      height: 1px;
    }
  }

  .drop-bottom {
    padding-top: 12px;

    button {
      padding: 7px 19px;
      font-size: 17px;
      position: relative;
      max-width: 250px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #fff;
    }

    .total {
      color: #1d71b8;
      padding-left: 0;
      margin-left: auto;
    }
    .el-button i {
      margin-right: 20px;
      font-size: 20px;
      padding-bottom: 0;
      display: initial;
    }
    .goto-cart-disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .drop-items {
    list-style: none;
    padding-left: 0;
    max-height: 284px;
    overflow: auto;

    li {
      padding: 16px 25px 16px 14px;
      border-bottom: 1px solid #f3f3f3;
      position: relative;

      .drop-item--img {
        width: 46px;
        margin-right: 20px;
      }

      .drop-item--info {
        width: 100%;
      }

      .product-name {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 7px;
      }

      .price-box {
        position: relative;
        float: right;
        text-align: right;
        padding-right: 10px;
        color: #000;
      }

      .price {
        font-size: 16px;
        font-weight: 500;
      }

      .quantity-box {
        position: relative;
        float: left;
      }

      .lnr-cross {
        position: absolute;
        right: 3px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        font-size: 19px;

        &.cart {
          top: 16px;
          // color: #e30613;
          font-weight: 500;
          transform: none;
        }
      }
    }
  }
  .drop-items::-webkit-scrollbar {
    display: none;
  }
}
</style>
