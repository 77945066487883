<template>
  <span v-if="typeof getCmsData == 'object'">
    {{ getCmsData.title }}
  </span>
</template>

<script setup>
import { computed, onServerPrefetch, onMounted } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  id: {
    type: Number,
    default: null,
    required: false,
  },
  identifier: {
    type: String,
    default: null,
    required: false,
  },
  sync: {
    type: Boolean,
    default: false,
    required: false,
  },
  showtitle: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const store = useStore();

const fetchCmsBlock = () => {
  let queryKey = "";
  let queryValue = "";
  if (props.id) {
    queryKey = "id";
    queryValue = props.id;
  } else if (props.identifier) {
    queryKey = "identifier";
    queryValue = props.identifier;
  }
  if (queryKey && queryValue) {
    return store.dispatch("cmsBlock/single", {
      key: queryKey,
      value: queryValue,
    });
  }
};

onServerPrefetch(() => {
  return fetchCmsBlock();
});

onMounted(() => {
  fetchCmsBlock();
});

const getCmsData = computed(() => {
  if (props.id) {
    return store.getters["cmsBlock/getCmsBlockById"](props.id);
  } else if (props.identifier) {
    return store.getters["cmsBlock/getCmsBlockByIdentifier"](props.identifier);
  }
  return null;
});
</script>
