<template>
  <nav id="nav">
    <router-link to="#" class="nav-opener" @click="toggleNav">
      <span></span>
    </router-link>
    <div class="nav-overlay" @click="toggleNav" :class="{ show: navExpanded }"></div>
    <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
    <transition name="slide-fade">
      <div class="nav-collapse" :class="{ show: navExpanded }">
        <NavbarContent @clicked="toggleNav" />
      </div>
    </transition>
  </nav>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import NavbarContent from "./NavbarContent.vue";

// Setup the store access
const store = useStore();

// Computed property using Composition API
const navExpanded = computed({
  get() {
    return store.getters["menu/getNavbar"];
  },
  set() {
    // Nothing to do
  },
});

const toggleNav = () => {
  store.dispatch("menu/toggleNavbar");
};

const resize = () => {
  document.body.classList.add("resize-active");
  if (window.innerWidth > 991) {
    navExpanded.value = false;
  }
  clearTimeout(resizeTimer);
  resizeTimer();
};

const resizeTimer = () => {
  setTimeout(() => {
    document.body.classList.remove("resize-active");
  }, 400);
};

onMounted(() => {
  window.addEventListener("resize", resize);
});

onUnmounted(() => {
  window.removeEventListener("resize", resize);
});
</script>
