import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export function useNewsletter() {
  const store = useStore();
  const { t } = useI18n();
  const emailReg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

  const isLoggedIn = computed(() => store.getters["user/getIsLoggedIn"]);

  onMounted(async () => {
    await store.dispatch("user/loadUser", { store });
  });

  const subscribe = async (email) => {
    let valid = true;
    if (email === "") {
      const msg = {
        type: "danger",
        title: t("newsletter_error"),
        text: t("email_is_required_field"),
      };
      store.dispatch("messages/sendMessage", { message: msg });
      valid = false;
    } else if (!emailReg.test(email)) {
      const msg = {
        type: "danger",
        title: t("newsletter_error"),
        text: t("email_is_invalid_field"),
      };
      store.dispatch("messages/sendMessage", { message: msg });
      valid = false;
    }
    if (valid) {
      // let customEmailValidationArr = email.slice(email.indexOf("@")).split(".");
      // if (
      //   customEmailValidationArr.length > 2 &&
      //   customEmailValidationArr[customEmailValidationArr.length - 1] ===
      //     customEmailValidationArr[customEmailValidationArr.length - 2]
      // ) {
      //   const msg = {
      //     type: "danger",
      //     title: t("newsletter_error"),
      //     text: t("email_is_invalid_field"),
      //   };
      //   store.dispatch("messages/sendMessage", { message: msg });
      //   valid = false;
      // } else {
      const retval = await store.dispatch("user/newsletterSignup", { email, store });
      if (retval !== false) {
        const msg = {
          type: "success",
          title: t("newsletter_success"),
          text: t("newsletter_subscribed"),
        };
        store.dispatch("messages/sendMessage", { message: msg });
      }
      // }
    }
  };

  return {
    isLoggedIn,
    subscribe,
  };
}
