<template>
  <div>
    <span class="mobile-menu-header d-lg-none">{{ $t("navbar_choose_category") }}</span>
    <BLink class="nav-opener inner-close" @click="toggleNav"></BLink>
    <ul class="nav-menu">
      <li class="nav-item">
        <span class="nav-link has-no-submenu"
          ><BLink to="/" v-on:click="closeInternal">{{ $t("home") }}</BLink></span
        >
      </li>
      <li
        class="nav-item"
        v-for="navItem of navItems"
        :key="navItem.id"
        @mouseover="onOver(navItem.id)"
        @mouseleave="onLeave(navItem.id)"
      >
        <div
          class="nav-link"
          :class="{ 'has-no-submenu': !navItem?.hasDropMenu }"
          @click="onToggle(navItem.id)"
        >
          <BLink :to="navItem.linkTo" @click.stop="onClick(navItem.id, navItem.linkTo)">{{
            navItem.itemName
          }}</BLink>
        </div>

        <HeaderSubmenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu' + navItem.id"
          :id="navItem.id"
          @setHeight="setHeight"
          @resetHeight="resetHeight"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import HeaderSubmenu from "@/base/core/components/HeaderSubmenu";

const emit = defineEmits("clicked");

const store = useStore();
const router = useRouter();

const menuHeight = ref({
  default: 315,
  current: 315,
});

const navItems = computed(() => store.getters["menu/getMenuItems"]);

const closeInternal = () => {
  store.commit("menu/setNavBar", false);
};

const onOver = (id) => {
  if (window.innerWidth > 991) {
    store.commit("menu/updateMenuState", { id: id, state: true });
  }
};

const onToggle = (id) => {
  if (window.innerWidth <= 991) {
    const vis = store.getters["menu/getMenuItemState"](id);
    if (vis.state == false) {
      store.commit("menu/updateMenuState", { id: id, state: true });
    } else {
      store.commit("menu/updateMenuState", { id: id, state: false });
    }

    navItems.value.forEach(
      ({ id: i }) => i != id && store.commit("menu/updateMenuState", { id: i, state: false })
    );
  }
};

const onLeave = (id) => {
  if (window.innerWidth > 991) {
    store.commit("menu/updateMenuState", { id: id, state: false });
  }
};

const onClick = (id, route) => {
  store.commit("menu/updateMenuState", { id: id, state: false });
  store.commit("menu/setNavBar", false);
  router.push(route).catch(() => {});
};

const toggleNav = (event) => {
  emit("clicked", event);
};

const setHeight = (height) => {
  if (height > menuHeight.value.default) {
    menuHeight.value.current = height;
  } else {
    resetHeight();
  }
};

const resetHeight = () => {
  menuHeight.value.current = menuHeight.value.default;
};
</script>
