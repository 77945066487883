<template>
  <div class="footer-copyright">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="text-center" v-html="getCopyright"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const getCopyright = computed(() => {
  let copyright = store.getters[`cmsBlock/getCmsBlockByIdentifier`]("footer_copyright");

  const date = new Date();
  copyright = copyright?.content
    .replace(
      /copyright/i,
      "Copyright &copy; " +
        date.getFullYear() +
        ' | powered by <a href="https://e-tailors.nl/" title="e-tailors" target="_blank">e-tailors</a>'
    )
    .replace(/<router-link((?!<\/router-link>).+?)to="(.*?)<\/router-link>/g, '<a$1href="$2</a>');
  return copyright;
});
</script>
