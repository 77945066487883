import { computed } from "vue";
import { useStore } from "vuex";
import config from "@storefront/core/lib/utils/config";
import { createToast } from "@storefront/core/helpers";

export function useCartInfo() {
    const store = useStore();

    let debounceTimer = null;
    const debounce = (fn, delay) => {
        return (...args) => {
            if (debounceTimer) clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => fn(...args), delay);
        };
    };

    const wishlistCount = computed(() => store.getters["user/getWishlistQuantity"]);

    const isLoggedIn = computed(() => store.getters["user/getIsLoggedIn"]);

    const cartCount = computed(() => {
        if (store.getters["cart/getCartIsLoaded"]) {
            return store.getters["cart/getItemsTotalQuantity"];
        }
        return 0;
    });

    const cartItems = computed(() => store.getters["cart/getCartItems"]);

    const wishlistItems = computed(() => store.getters["user/getWishlist"]);

    const cartTotalPrice = computed(() => {
        if (store.getters["cart/getCartIsLoaded"]) {
            const prices = store.getters["cart/getCartPrices"];
            return prices?.grand_total?.value || 0;
        }
        return 0;
    });

    const defaultShipping = computed(() => store.getters["cart/getDefaultShipping"]);
    const getShippingMethod = computed(() => store.getters["cart/getShippingMethod"]);

    const cartSubTotalPrice = computed(() => {
        if (store.getters["cart/getCartIsLoaded"]) {
            const prices = store.getters["cart/getCartPrices"];
            return prices?.subtotal_including_tax?.value || 0;
        }
        return 0;
    });

    const cartTotalTaxPrices = computed(() => {
        if (store.getters["cart/getCartIsLoaded"]) {
            const prices = store.getters["cart/getCartPrices"];
            return prices?.applied_taxes || [];
        }
        return [];
    });

    const cartTotalDiscounts = computed(() => {
        if (store.getters["cart/getCartIsLoaded"]) {
            const prices = store.getters["cart/getCartPrices"];
            return prices?.discounts || [];
        }
        return [];
    });

    // Methods for cart actions
    const removeItem = debounce((id) => {
        const found = cartItems.value.find((item) => item.id === id);
        if (found && window.dataLayer) {
            handleRemoveFromCartTracking(found);
        }
        store.dispatch("cart/removeCartItem", { id, store });
        if (config.helloRetail?.enabled) {
            setTimeout(HrClearCart, 1000);
        }
    }, 500);

    const quantityDown = (id, quantity) => {
        if (quantity > 1) {
            quantity--;
            store.dispatch("cart/updateCartItem", { id, quantity, store });
            const found = cartItems.value.find((item) => item.id === id);
            if (found && window.dataLayer) {
                handleRemoveFromCartTracking(found, quantity);
            }
        } else {
            const msg = {
                type: "danger",
                title: "Minimale hoeveelheid",
                text: "Aantal mag niet kleiner zijn dan 1"
            };
            createToast(msg);
        }
        if (config.helloRetail?.enabled) {
            setTimeout(HrClearCart, 1000);
        }
    };

    const quantityUp = (id, quantity) => {
        quantity++;
        store.dispatch("cart/updateCartItem", { id, quantity, store });
        const found = cartItems.value.find((item) => item.id === id);
        if (found && window.dataLayer) {
            handleAddToCartTracking(found, quantity);
        }
    };

    const HrClearCart = () => {
        if (cartCount.value === 0) {
            self.ADDWISH_PARTNER_NS.api.cart.clearCart(() => {
                // Cart has been cleared
            });
        }
    };

    // Tracking functions for GTM and GA4
    const handleRemoveFromCartTracking = (item, quantity = item.quantity) => {
        window.dataLayer.push({
            event: "remove_from_cart",
            ecommerce: {
                currency: "EUR",
                value: item.prices.price.value * quantity,
                items: [
                    {
                        item_name: item.product.name,
                        item_id: item.product.sku,
                        price: item.prices.price.value,
                        quantity,
                    },
                ],
            },
        });
    };

    const handleAddToCartTracking = (item, quantity) => {
        window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
                currency: "EUR",
                value: item.prices.price.value * quantity,
                items: [
                    {
                        item_name: item.product.name,
                        item_id: item.product.sku,
                        price: item.prices.price.value,
                        quantity,
                    },
                ],
            },
        });
    };

    return {
        wishlistCount,
        isLoggedIn,
        cartCount,
        cartItems,
        wishlistItems,
        cartTotalPrice,
        cartSubTotalPrice,
        cartTotalTaxPrices,
        cartTotalDiscounts,
        defaultShipping,
        removeItem,
        quantityDown,
        quantityUp,
        getShippingMethod,
    };
}
