<template>
  <section class="our-stores">
    <div class="container">
      <span class="section-heading font-weight-normal text-center d-block"> Onze winkel </span>
      <div v-if="storeImages.length > 1" class="row">
        <div
          v-for="(storeImage, index) in storeImages"
          :key="`stores-${index}`"
          class="col-12 col-md-6"
        >
          <router-link
            :to="`/winkel/` + storeImage.url_key"
            :aria-label="`Go to ${storeImage.name}`"
          >
            <div
              v-if="storeImage.base_image != null"
              :style="{ backgroundImage: `url(${storeImage.base_image.url})` }"
              class="img-holder"
            ></div>
          </router-link>
        </div>
      </div>

      <div v-else class="row">
        <div
          v-for="(images, imgindex) in storeImages[0]?.images"
          :key="`stores-${imgindex}`"
          class="col-12 col-md-6"
        >
          <router-link
            :to="`/winkel/` + storeImages[0].url_key"
            :aria-label="`Go to ${storeImages[0].name}`"
          >
            <div
              v-if="images.url != null"
              :style="{ backgroundImage: `url(${images.url})` }"
              class="img-holder"
            ></div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const storeImages = computed(() => store.getters["stores/getStores"]);
</script>
