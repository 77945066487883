<template>
  <div id="wrapper">
    <Header />
    <main id="main" :class="wrapperClass">
      <slot></slot>
    </main>
    <Footer />
    <div v-if="isClient">
      <CookieBarWrap />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import Header from "@/esf_thehague_fietskledingvoordeel/core/components/Header.vue";
import Footer from "@/esf_thehague_fietskledingvoordeel/core/components/Footer";
import CookieBarWrap from "@/esf_thehague_fietskledingvoordeel/core/components/CookieBar";

const isClient = ref(false);

onMounted(() => {
  isClient.value = true;
});

defineProps({
  wrapperClass: {
    type: String,
    required: false,
  },
});
</script>
