<template>
  <section class="most-purchased" v-if="mostPurchasedProducts && mostPurchasedProducts.length > 0">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <span class="section-heading font-weight-normal text-center d-block">Meest verkocht</span>
        </div>
      </div>
      <Carousel
        v-if="isClient"
        :autoplay="3000"
        :wrapAround="true"
        :breakpoints="breakpoints"
        :pauseAutoplayOnHover="true"
        :itemsToShow="1"
        snapAlign="start"
      >
        <Slide
          class="filtered-products text-start"
          v-for="product in mostPurchasedProducts"
          :key="product.id"
        >
          <ProductCard :product="product" :optionHeight="getOptionHeight" />
        </Slide>
        <template #addons>
          <Navigation>
            <template #prev>
              <i class="lnr lnr-chevron-left"></i>
            </template>
            <template #next>
              <i class="lnr lnr-chevron-right"></i>
            </template>
          </Navigation>
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import ProductCard from "@/base/core/components/ProductCard";

import { Carousel, Slide, Navigation } from "vue3-carousel/dist/carousel.es.js";

const store = useStore();
const isClient = ref(false);

const breakpoints = {
  769: {
    itemsToShow: 3,
  },
};

const mostPurchasedProducts = computed(() => store.getters["home/getMostPurchasedProducts"]);

const getOptionHeight = computed(() => {
  let maxCount = 0;
  mostPurchasedProducts.value.forEach((p) => {
    if (p.configurable_options) {
      p.configurable_options.forEach((co) => {
        if (co.values && co.values.length > maxCount) {
          maxCount = co.values.length;
        }
      });
    }
  });
  return Math.floor(maxCount / 4) + 1;
});

onMounted(() => {
  isClient.value = true;
});
</script>

<style scoped>
:deep(.carousel__prev) {
  transform: translateY(-50%) translateX(-100%);
  font-family: "system";
  padding: 8px;
  margin-right: -8px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    left: 10px;
  }
}

:deep(.carousel__next) {
  transform: translateY(-50%) translateX(100%);
  font-family: "system";
  padding: 8px;
  margin-left: -8px;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    right: 10px;
  }
}
</style>
