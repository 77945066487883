<template>
  <el-collapse-item
    :name="index.toString()"
    class="accordion-block"
    :disabled="windowSize.width >= 768"
  >
    <template #title>
      <a
        href="#"
        class="text-uppercase acc-opener block-heading"
        v-if="typeof accordionData != 'undefined'"
      >
        {{ accordionData.title }}
      </a>
    </template>
    <cmsBlockSimple
      v-if="typeof accordionData == 'object'"
      :identifier="accordionData.identifier"
    />
  </el-collapse-item>
</template>

<script setup>
import cmsBlockSimple from "@/base/core/components/core/BlockSimple";
import { useWindowSize } from "@storefront/core/composable/useWindowSize";

const windowSize = useWindowSize();

defineProps(["accordionData", "index"]);
</script>
