<template>
  <transition name="back-to-top-fade">
    <div
      class="vue-back-to-top"
      :style="`bottom:${props.bottom};right:${props.right};`"
      v-if="isClient"
      v-show="visible"
      @click="backToTop"
    >
      <slot>
        <div class="default">
          <span>
            {{ props.text }}
          </span>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const isClient = ref(false);

const props = defineProps({
  text: {
    type: String,
    default: "Voltar ao topo",
  },
  visibleoffset: {
    type: [String, Number],
    default: 600,
  },
  visibleoffsetbottom: {
    type: [String, Number],
    default: 0,
  },
  right: {
    type: String,
    default: "30px",
  },
  bottom: {
    type: String,
    default: "40px",
  },
  scrollFn: {
    type: Function,
    default: function () {},
  },
});

const emit = defineEmits(["scrolled"]);

const visible = ref(false);

/**
 * Catch window scroll event
 * @return {void}
 */
function catchScroll() {
  const pastTopOffset = window.pageYOffset > parseInt(props.visibleoffset);
  const pastBottomOffset =
    window.innerHeight + window.pageYOffset >=
    document.body.offsetHeight - parseInt(props.visibleoffsetbottom);
  visible.value =
    parseInt(props.visibleoffsetbottom) > 0 ? pastTopOffset && !pastBottomOffset : pastTopOffset;
  props.scrollFn();
}

/**
 * The function who make the magics
 * @return {void}
 */
function backToTop() {
  window.smoothscroll();
  emit("scrolled");
}

onMounted(() => {
  isClient.value = true;
  window.smoothscroll = () => {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentScroll > 0) {
      window.requestAnimationFrame(window.smoothscroll);
      window.scrollTo(0, Math.floor(currentScroll - currentScroll / 5));
    }
  };
  window.addEventListener("scroll", catchScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", catchScroll);
});
</script>
