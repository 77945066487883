<template>
  <div v-if="!isCookieAgreed" class="cookie-bar-wrap">
    <div class="container">
      <div class="cookie-wrap">
        <div class="img">
          <img
            src="@/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png
            "
            srcset="
              @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png 253w,
              @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png 175w,
              @/esf_thehague_fietskledingvoordeel/assets/FietskledingVoordeelNL_1-opwit.png  75w
            "
            width="320"
            height="57"
            alt="Logo"
          />
        </div>
        <div class="text">
          <strong class="title">{{ $t("cookiebar_title") }}</strong>
          <p>
            {{ $t("cookiebar_text") }}
          </p>
          <div class="sub-wrap">
            <div class="checkbox-block">
              <label>
                <input type="checkbox" checked="checked" name="check01" disabled />
                <span class="fake-check">{{ $t("cookiebar_necessary") }}</span>
              </label>
              <label>
                <input type="checkbox" v-model="allCookies" name="check02" />
                <span class="fake-check">{{ $t("cookiebar_other") }}</span>
              </label>
            </div>
            <div class="btn-wrap">
              <button class="btn btn-primary text-white" @click="onClick">
                {{ $t("cookiebar_accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import VueCookies from "vue-cookies";
import { useGtm } from "@gtm-support/vue-gtm";

const cookies = VueCookies;
const gtm = useGtm();

const allCookies = ref(true);
const isCookieAgreed = ref(false);

const setGoogleConsent = (consent) => {
  if (typeof window.gtag !== "function") {
    window.gtag = function () {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(arguments);
    };
  }
  window.gtag("consent", "default", formatConsent(consent));
};

const updateGoogleConsent = (consent) => {
  window.gtag("consent", "update", formatConsent(consent));
};

const formatConsent = (consent) => {
  return {
    ad_storage: consent,
    analytics_storage: consent,
    functionality_storage: consent,
    personalization_storage: consent,
    security_storage: "granted",
    ad_user_data: consent,
    ad_personalization: consent,
    wait_for_update: 2000,
  };
};

const onClick = () => {
  isCookieAgreed.value = true;
  cookies.config("180d");
  cookies.set("websiteCookie", true);
  if (allCookies.value) {
    updateGoogleConsent("granted");
  } else {
    updateGoogleConsent("denied");
  }
};

onMounted(() => {
  isCookieAgreed.value = cookies.isKey("websiteCookie");

  setGoogleConsent("denied");
  gtm.enable(true);
  if (allCookies.value && isCookieAgreed.value) {
    updateGoogleConsent("granted");
  } else {
    updateGoogleConsent("denied");
  }
});
</script>
