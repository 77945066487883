<template>
  <section class="image-gallery">
    <div class="container">
      <div class="row" v-if="typeof images === 'object'">
        <div class="col-12 col-md-6 img-grid" v-for="image in images.slides" :key="image.id">
          <BLink :to="image.link" :aria-label="image.description ? image.description : image.title">
            <div class="img-holder" :style="{ backgroundImage: `url(${image.media.url})` }"></div>
          </BLink>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const images = computed(() => store.getters["sliders/getSliderByIdentifier"]("home_gallery"));
</script>
