<template>
  <div ref="wishlist">
    <BCollapse
      :id="'topIconsCollapse' + showType + icon.iconNumber"
      :class="{ collapsed: visible }"
      :aria-expanded="visible"
      class="top-icons--drop"
      v-model="visible"
    >
      <span v-if="icon.dropHeading" class="drop-heading">{{ icon.dropHeading }}</span>
      <ul class="drop-items">
        <li v-for="(dropItem, index) of icon.dropItems" :key="`wl-${index}`">
          <BLink
            :to="`/${dropItem.product.url_key}`"
            class="d-flex align-items-start"
            @click="closeOnClick"
          >
            <div class="drop-item--img">
              <img :src="dropItem.product.image.x_small" :alt="dropItem.product.sku" />
            </div>
            <div class="drop-item--info">
              <span class="product-name d-block">{{ dropItem.product.name }}</span>
              <span class="price d-block"
                >&euro;{{
                  dropItem.product.price_range.minimum_price.final_price.value.toFixed(2)
                }}</span
              >
            </div>
          </BLink>
          <a href="#" class="lnr lnr-cross" @click.prevent="removeItem(dropItem.id)"></a>
        </li>
      </ul>
      <div
        class="d-flex drop-bottom justify-content-between align-items-center"
        v-if="icon.cartFooter"
      >
        <span class="total">Total: € {{ icon.cartFooter.totalPrice }}</span>
        <BButton variant="success">
          <i class="fas fa-check-circle"></i>
          <i class="lnr lnr-cart"></i>{{ icon.cartFooter.btnText }}
        </BButton>
      </div>
      <div class="language-block" v-if="icon.language">
        <ul class="d-flex list-unstyled">
          <li v-for="lang of icon.language" :key="lang.alt">
            <BLink :href="lang.href"
              ><BImg :src="lang.img" :alt="lang.lang" width="39" height="39" fluid></BImg>
            </BLink>
          </li>
        </ul>
      </div>
    </BCollapse>
  </div>
</template>

<script setup>
import { ref, useTemplateRef, watch } from "vue";
import { useStore } from "vuex";

defineProps({
  icon: Object,
  showType: String,
});

// Check if the code is running on the server
const isServer = typeof window === "undefined";

const store = useStore();
const visible = ref(false);
const wishlistRef = useTemplateRef("wishlist");

function closeDropdownMenu(e) {
  if (
    wishlistRef.value &&
    wishlistRef.value.parentElement &&
    !wishlistRef.value.parentElement.contains(e.target)
  ) {
    visible.value = false;
    document.removeEventListener("click", closeDropdownMenu);
  }
}

watch(visible, () => {
  if (!isServer) {
    if (visible.value === true) {
      document.addEventListener("click", closeDropdownMenu);
      document.body.classList.add("noScroll1");
    } else {
      document.body.classList.remove("noScroll1");
    }
  }
});

function removeItem(id) {
  store.dispatch("user/removeProductWishlist", { id, store });
}

function closeOnClick() {
  visible.value = false;
}
</script>
<style scoped lang="scss">
.top-icons--drop {
  width: 446px;
  max-width: none;

  span.drop-heading {
    display: block;
    font-size: 16px;
    color: #000;
    margin-bottom: 14px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: calc(100% + 14px);
      left: -14px;
      right: -14px;
      background: #f3f3f3;
      height: 1px;
    }
  }

  .drop-items {
    list-style: none;
    padding-left: 0;
    max-height: 330px;
    overflow: auto;

    li {
      padding: 16px 25px 16px 14px;
      border-bottom: 1px solid #f3f3f3;
      position: relative;

      .lnr-cross {
        position: absolute;
        right: 3px;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        font-size: 19px;
      }

      .drop-item--img {
        width: 46px;
        margin-right: 20px;
      }

      .product-name {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.2;
        margin-bottom: 7px;
      }

      .price {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .drop-items::-webkit-scrollbar {
    display: none;
  }
}
</style>
