<template>
  <!-- <v-runtime-template
    
    v-if="getCmsData"
    :template="`<div>` +  + `</div>`"
  ></v-runtime-template> -->
  <div :class="['cms-block-content', { container: sync }]" v-if="getCmsData">
    <div v-html="getCmsData.content"></div>
  </div>
</template>

<script setup>
// import VRuntimeTemplate from "vue3-runtime-template";
import { computed, onBeforeMount, onServerPrefetch } from "vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  id: {
    type: Number,
    default: null,
    required: false,
  },
  identifier: {
    type: String,
    default: null,
    required: false,
  },
  sync: {
    type: Boolean,
    default: false,
    required: false,
  },
  showtitle: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const getCmsData = computed(() => {
  if (props.id) {
    return store.getters[`cmsBlock/getCmsBlockById`](props.id);
  } else if (props.identifier) {
    return store.getters[`cmsBlock/getCmsBlockByIdentifier`](props.identifier);
  }
  return null;
});

function fetchCmsBlock() {
  let queryKey = "";
  let queryValue = "";
  if (props.id) {
    queryKey = "id";
    queryValue = props.id;
  } else if (props.identifier) {
    queryKey = "identifier";
    queryValue = props.identifier;
  }
  if (queryKey && queryValue) {
    return store.dispatch("cmsBlock/single", {
      key: queryKey,
      value: queryValue,
    });
  }
}

onBeforeMount(() => {
  fetchCmsBlock();
});

onServerPrefetch(fetchCmsBlock);
</script>
